<template>
    <div class="content">
        <div class="search">
          <el-button type="primary" class="el-icon-phone-outline" @click='refreshUpload' style="margin-right: 10px;"> 预约</el-button>
          <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="访客预约" :fetch="createExportData" :before-finish="finishDownload" worksheet="访客预约">
              <el-button type="primary" class="el-icon-download"> 导出</el-button>
          </JsonExcel>
          <el-button type="primary" class="el-icon-upload2" @click='refreshUpload' style="margin-left: 10px;"> 重新上传</el-button>
        </div>
        <el-card style="margin-top:15px;width:100%" class="box-card">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <div class="search">
                 <label>预约时间：</label>
                 <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <div class="search">
                  <label>车牌号：</label>
                  <el-input v-model="searchForm.plateno" class="marg" style="width:150px"></el-input>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <div class="search">
                  <label>状态：</label>
                  <el-select v-model="searchForm.opt_type" style="width:150px" @change="parkChanged">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="正常" :value="1"></el-option>
                    <el-option label="已完成" :value="2"></el-option>
                  </el-select>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <div v-if="User.user_type!==5" class="search">
                <label>车场：</label>
                <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                  <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                  <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>

          <el-table :data='DataList' border style="width: 100%;font-size:13px" max-height='calc(100vh - 250px)' height='calc(100vh - 250px)' stripe :highlight-current-row='true' size="mini">
            <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场"></el-table-column>
            <el-table-column align="center" prop="log_plateno" label="车牌号" min-width="145px"></el-table-column>
            <el-table-column align="center" prop="log_visitor_name" label="访客" min-width="145px"></el-table-column>
            <el-table-column align="center" prop="log_opt_time" label="操作时间" min-width="145px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_opt_time)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="预约入场时段" min-width="160px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_reserve_begintime)+" 至 "+FormData(scope.row.log_reserve_endtime)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="log_visitor_phone" label="联系方式" min-width="145px"></el-table-column>
            <el-table-column align="center" prop="log_remark" label="备注"></el-table-column>
            <el-table-column align="center" prop="log_limit_count" label="次数限制">
                <template slot-scope="scope">
                    {{scope.row.log_limit_count===0?"不限次":(scope.row.log_limit_count+"次")}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="log_state" label="状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.log_state === 0 ? 'success' : 'warning'" disable-transitions>{{scope.row.log_state===0?"正常":"已完成"}}</el-tag>
                </template>
            </el-table-column>
          </el-table>
          <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @size-change="handleSizeChange" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
  
        <el-dialog title="访客预约" :visible.sync="dialogVisible" :append-to-body='true'>
          <el-form :model="devForm" :rules="rules" ref="devForm" label-width="100px" size='medium'>
              <el-form-item label="设备名称" prop="dev_name">
                  <el-input v-model="devForm.dev_name"></el-input>
              </el-form-item>
              <el-form-item label="设备类型">
                  <el-select v-model="devForm.dev_type" style="width:100%;">
                    <el-option label="摄像机" :value='0'></el-option>
                    <el-option label="Lcd屏" :value='1'></el-option>
                    <el-option label="余位屏" :value='2'></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="IP 地址"  prop="dev_ip">
                  <el-input v-model="devForm.dev_ip"></el-input>
              </el-form-item>
              <el-form-item label="序列号" v-if="devForm.dev_type===0">
                  <el-input v-model="devForm.dev_mac"></el-input>
              </el-form-item>
              <el-form-item label="尺寸大小" v-if="devForm.dev_type===2">
                <div class="a">
                  <el-input-number v-model="devForm.dev_yw_width" :min="1" label="宽" controls-position="right"></el-input-number>
                  <span style="margin-left: 5px;margin-right: 5px;">*</span>
                  <el-input-number v-model="devForm.dev_yw_height" :min="1" label="高" controls-position="right"></el-input-number>
                </div>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="submit">确 定</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
import util from '../../../util/util'
import JsonExcel from 'vue-json-excel'

export default{
    data:function(){
        return{
          User:null,
            searchForm:{
              pk_id:0,
                pageNo:1,
                pageSize:15,
                total:0,
                time:[],
                plateno:'',
                opt_type:0
            },
          
            dialogVisible:false,
            visitorForm:{
              log_plateno:'',
              log_reserve_begintime:'',
              log_reserve_endtime:'',
              log_visitor_name:'',
              log_visitor_phone:'',
              log_remark:'',
              log_limit_count:0
            },


            DataList:[],
            ParkDataList:[],

            exportDataStandard: {
              所属车场: "park_name" ,
              车牌号: "log_plateno" ,
              访客: "log_visitor_name" ,
              操作时间: {
                field: 'log_opt_time',
                callback: value => {
                  return this.FormData(value)
                }
              },
              预约入场起始时间: {
                field: 'log_reserve_begintime',
                callback: value => {
                  return this.FormData(value)
                }
              },
              预约入场截止时间: {
                field: 'log_reserve_endtime',
                callback: value => {
                  return this.FormData(value)
                }
              },
              预约次数限制: {
                field: 'log_limit_count',
                callback: value => {
                  return value===0?"不限次":(value+'次')
                }
              },
              联系方式: "log_visitor_phone" ,
              备注: "log_remark" ,
              状态:{
                field: 'log_state',
                callback: value => {
                  return value===0?"正常":'已完成'
                }
              },
            },
            exportData: []
        }
    },
    computed:{
        FormData () {
          return function (time) {
            if(time===null||time===undefined||time==='')
              return ''
            else
              return util.FormateDate(time, 2)
          }
        },
    },
    components: {
      JsonExcel
    },
    created(){
        this.User = util.JesToken()
        this.getParkDataList()
    },
    methods:{
        getParkDataList(){
        if(this.User.user_type===5){
            this.GetDataList()
        }
        else{
          util.Get('parkmanage/getparkdatalist').then(res => {
            if (res.rpStatus === 10000) {
               this.ParkDataList = res.list
               if(res.list.length>0){
                 this.searchForm.pk_id=res.list[0].pk_id
                 this.GetDataList()
               }
            }
          })
        }
      },
        parkChanged(value){
            this.searchForm.pk_id=value
            this.GetDataList()
        },
        GetDataList(){
            this.searchForm.begintime=''
            this.searchForm.endtime=''
            if(this.searchForm.time!=undefined&&this.searchForm.time!==null&&this.searchForm.time.length>0){
                this.searchForm.begintime=this.searchForm.time[0]
                this.searchForm.endtime=this.searchForm.time[1]
            }

            util.Get('logreserve/getlogdataList',this.searchForm).then(res=>{
                if(res.rpStatus===10000){
                    this.searchForm.total=res.total
                    res.list.forEach(element => {
                        element.picdata = []
                    })
                    this.DataList=res.list
                   
                }
            })
        },
        current_change(page){
            this.searchForm.pageNo=page
            this.GetDataList()
        },
        handleSizeChange(pageSize){
            this.searchForm.pageSize=pageSize
            this.GetDataList()
        },
        Search(){
          if(util.CheckUserAuth('4-7-1')){
            this.GetDataList()
          }
        },
        refreshUpload(){
          util.Send('refresh_upload_logreserve',this.searchForm.pk_id)
        },
        // 导出
        async createExportData () {
          if(util.CheckUserAuth('4-7-2')){
            this.searchForm.pageNo = 0
            var form = JSON.parse(JSON.stringify(this.searchForm))
            form.pageNo = 0

            var result = await util.Get('logreserve/getlogdataList', form)
            if (result.rpStatus === 10000) {
              this.exportData = result.list
              return this.exportData
            }
          }
        },
        finishDownload () {
          this.$message({
            message: '导出数据成功',
            type: 'success'
          })
          this.exportData=[]
        },


        //预约
        submit(){

        }


    }
}
</script>

<style scoped>
  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.el-table{
        margin-top: 15px;
    }
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.search label{
  margin-right: 8px;
  width: 90px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
::v-deep.el-table thead {
  color: #666666;
  font-weight: 500;
}
</style>